import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView';

export default [
  {
    path: '',
    component: {
      render
    },
    children: [
      {
        path: 'meeting',
        name: 'VMeeting',
        component: () => import('../views/VMeeting').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Бронирование',
        },
      },
      {
        path: 'reservation',
        name: 'VReservation',
        component: () => import('../views/VMeeting/VReservation').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Бронирование'
        },
      },
    ]
  }
]