import instance  from "@/api/instance";
const END_POINT = '/api';

const positions = {
    getList: (data) => instance.get(`${END_POINT}/positions`,data),
    getLocalizationList: (data) => instance.get(`${END_POINT}/positions/list`,data),
    add: (data) => instance.post(`${END_POINT}/positions`, data),
    edit: (positionId,data) => instance.put(`${END_POINT}/positions/${positionId}`, data),
    delete: (positionId) => instance.delete(`${END_POINT}/positions/${positionId}`),
    isShow: (data) => instance.post(`${END_POINT}/is-show/position`,data),
    deletedItems: (data) => instance.post(`${END_POINT}/delete/position`,data),
    restore:(data) => instance.post(`${END_POINT}/restore/position`,data)
}

const cabinets = {
    getList: (data) => instance.get(`${END_POINT}/cabinets`,data),
    getLocalizationList: (data) => instance.get(`${END_POINT}/cabinets/list`,data),
    add: (data) => instance.post(`${END_POINT}/cabinets`, data),
    edit: (positionId,data) => instance.put(`${END_POINT}/cabinets/${positionId}`, data),
    delete: (positionId) => instance.delete(`${END_POINT}/cabinets/${positionId}`),
    isShow: (data) => instance.post(`${END_POINT}/is-show/cabinet`,data),
    deletedItems: (data) => instance.post(`${END_POINT}/delete/cabinet`,data),
    restore:(data) => instance.post(`${END_POINT}/restore/cabinet`,data)
}

const equipments = {
    getList: (data) => instance.get(`${END_POINT}/equipments`,data),
    getLocalizationList: (data) => instance.get(`${END_POINT}/equipments/list`,data),
    add: (data) => instance.post(`${END_POINT}/equipments`, data),
    edit: (positionId,data) => instance.put(`${END_POINT}/equipments/${positionId}`, data),
    delete: (positionId) => instance.delete(`${END_POINT}/equipments/${positionId}`),
    isShow: (data) => instance.post(`${END_POINT}/is-show/equipment`,data),
    deletedItems: (data) => instance.post(`${END_POINT}/delete/equipment`,data),
    restore:(data) => instance.post(`${END_POINT}/restore/equipment`,data)
}
const dish_categories = {
    getList: (data) => instance.get(`${END_POINT}/dish-categories`,data),
    add: (data) => instance.post(`${END_POINT}/dish-categories`, data),
    getLocalizationList: (data) => instance.get(`${END_POINT}/dish-categories/list`,data),
    edit: (positionId,data) => instance.put(`${END_POINT}/dish-categories/${positionId}`, data),
    delete: (positionId) => instance.delete(`${END_POINT}/dish-categories/${positionId}`),
    isShow: (data) => instance.post(`${END_POINT}/is-show/dish-categories`,data),
    deletedItems: (data) => instance.post(`${END_POINT}/delete/dish-categories`,data),
    restore:(data) => instance.post(`${END_POINT}/restore/dish-categories`,data)
}
const languages = {
    getList: (data) => instance.get(`${END_POINT}/languages`,data),
    getLocalizationList: () => instance.get(`${END_POINT}/languages/list`),
    add: (data) => instance.post(`${END_POINT}/languages`, data),
    edit: (positionId,data) => instance.put(`${END_POINT}/languages/${positionId}`, data),
    delete: (positionId) => instance.delete(`${END_POINT}/languages/${positionId}`),
    isShow: (data) => instance.post(`${END_POINT}/is-show/language`,data),
    deletedItems: (data) => instance.post(`${END_POINT}/delete/language`,data),
    restore:(data) => instance.post(`${END_POINT}/restore/language`,data)
}
const groups = {
    getList: (data) => instance.get(`${END_POINT}/groups`,data),
    add: (data) => instance.post(`${END_POINT}/groups`, data),
    edit: (positionId,data) => instance.put(`${END_POINT}/groups/${positionId}`, data),
    delete: (positionId) => instance.delete(`${END_POINT}/groups/${positionId}`),
    isShow: (data) => instance.post(`${END_POINT}/is-show/group`,data),
    deletedItems: (data) => instance.post(`${END_POINT}/delete/group`,data),
    restore:(data) => instance.post(`${END_POINT}/restore/group`,data)
}
const organaizes = {
    getList: (data) => instance.get(`${END_POINT}/organization-categories`,data),
    getLocalizationList: (data) => instance.get(`${END_POINT}/organization-categories/list`,data),
    add: (data) => instance.post(`${END_POINT}/organization-categories`, data),
    edit: (positionId,data) => instance.put(`${END_POINT}/organization-categories/${positionId}`, data),
    delete: (positionId) => instance.delete(`${END_POINT}/organization-categories/${positionId}`),
    isShow: (data) => instance.post(`${END_POINT}/is-show/organization-categories`,data),
    deletedItems: (data) => instance.post(`${END_POINT}/delete/organization-categories`,data),
    restore:(data) => instance.post(`${END_POINT}/restore/organization-categories`,data)
}
const lessons = {
    getList: (data) => instance.get(`${END_POINT}/section-categories`,data),
    getLocalizationList: (data) => instance.get(`${END_POINT}/section-categories/list`,data),
    add: (data) => instance.post(`${END_POINT}/section-categories`, data),
    edit: (positionId,data) => instance.put(`${END_POINT}/section-categories/${positionId}`, data),
    delete: (positionId) => instance.delete(`${END_POINT}/section-categories/${positionId}`),
    isShow: (data) => instance.post(`${END_POINT}/is-show/section-categories`,data),
    deletedItems: (data) => instance.post(`${END_POINT}/delete/section-categories`,data),
    restore:(data) => instance.post(`${END_POINT}/restore/section-categories`,data)
}
const navigation = {
    getList: (data) => instance.get(`${END_POINT}/navigations`,data),
    getLocalizationList: (data) => instance.get(`${END_POINT}/navigations/list`,data),
    add: (data) => instance.post(`${END_POINT}/navigations`, data),
    edit: (positionId,data) => instance.put(`${END_POINT}/navigations/${positionId}`, data),
    delete: (positionId) => instance.delete(`${END_POINT}/navigations/${positionId}`),
    isShow: (data) => instance.post(`${END_POINT}/is-show/navigation`,data),
    deletedItems: (data) => instance.post(`${END_POINT}/delete/navigation`,data),
    restore:(data) => instance.post(`${END_POINT}/restore/navigation`,data)
}
export default {
    positions,cabinets,equipments,dish_categories,languages,groups,organaizes,lessons,navigation
}