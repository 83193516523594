import router from "@/router";
import errorHandler from "@/helpers/errorHandler";

const state = {
    token: window.localStorage.getItem('pro-token') || '',
    lang: window.localStorage.getItem('language') || 'kz',
    user: JSON.parse(window.localStorage.getItem('user')) || '',
}

const mutations = {
    SET_TOKEN(state, payload) {
        state.token = payload;
    },
    AUTH_LOGOUT(state) {
        state.token = '';
    },
    setLocale(state, locale) {
        window.localStorage.setItem('language', locale);
        window.location.reload();
    },
    setProfile(state, payload) {
        state.user = payload;
        window.localStorage.setItem('user', JSON.stringify(payload));
    },
}

const getters = {
    loggedIn: (state) => !!state.token,
    profileAvatar: (state) => state.user?.avatar,
    getUserFullName: (state) => state.user?.full_name,
    getUserName: (state) => state.user?.first_name,
    getGroup: (state) => state.user?.group,
    getLogin: (state) => state.user?.login,
    getUserId:(state)=>state.user.id,
    getRoleId:(state)=>state.user?.role?.id,
    interfaceLang:(state)=>state.lang,
    getPosition:(state)=>state.user?.position.name,
    getUserRoleName:(state)=>state.user?.role.name,
}
const actions = {
    login({ commit }, token) {
        commit('SET_TOKEN', token);
        window.localStorage.setItem('pro-token', token)
    },
    logout({ commit }) {
        commit('AUTH_LOGOUT');
        window.localStorage.removeItem('pro-token');
        window.localStorage.removeItem('user');
        router.push('/login').catch(errorHandler)
    },
}

export default {
    state,
    mutations,
    getters,
    actions
}