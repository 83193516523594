import instance  from "@/api/instance";
const END_POINT = '/api';

const login = (data) => instance.post(`${END_POINT}/auth/login`, data);
const logout = () =>  instance.post(`${END_POINT}/auth/logout`)
const upload = (data) => instance.post(`${END_POINT}/file/upload`, data);


export default {
    login,
    logout,
    upload,
}