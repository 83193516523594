import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView';

export default [
  {
    path: 'appointment',
    component: {
      render
    },
    children: [
      {
        path: '',
        name: 'VAppointment',

        component: () => import('../views/VAppointment').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Бронирование',
        },
      },
    ]
  }
]