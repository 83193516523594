import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView';

export default [
  {
    path: 'students',
    component: {
      render
    },
    children: [
      {
        path: '',
        name: 'VStudents',

        component: () => import('../views/VStudents').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Школьники',
        },
      },
    ]
  }
]