import authApi from './auth.api';
import usersApi from "@/api/users.api";
import dictionaryApi from "@/api/dictionary.api";
import bookingApi from "@/api/booking.api";
import employeesApi from "@/api/employees.api";
import groupApi from "@/api/group.api";
import organizationsApi from "@/api/organizations.api";
import sectionsApi from "@/api/sections.api";
import translates from "@/api/translates.api";
import adverts from "@/api/adverts.api";
import news from "@/api/news.api";
import students from "@/api/students.api";
import certificates from "@/api/certificates.api";
import dishes from '@/api/dishes.api';
import questionnaires from '@/api/questionnaires.api'
import surveys from '@/api/surveys.api'
const API = {
    authApi,
    translates,
    ...usersApi,
    ...dictionaryApi,
    ...bookingApi,
    ...employeesApi,
    ...groupApi,
    ...organizationsApi,
    ...sectionsApi,
    ...adverts,
    ...news,
    ...students,
    ...certificates,
    ...dishes,
    ...questionnaires,
    ...surveys
}

export default API