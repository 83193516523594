import Vue from 'vue'
import VueRouter from 'vue-router'
import render from '../helpers/renderRouterView'
import store from '@/store'
import API from "@/api";
import sections from './sections'
import auth from './auth'
import dictionary from './dictionary'
import booking from './booking'
import employees from './employees'
import organization from './organization'
import classes from './classes'
import news from './news'
import advert from './advert'
import profile from "./profile";
import students from "./students";
import trialLesson from "./trialLesson";
import appointment from "./appointment";
import project from "./project";
import meeting from "./meeting";
import survey from "./survey";
import questionnaire from "./questionnaire";
import test from "./test";
import dishes from "./dishes";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: {
      render
    },
    children: [
      {
        path: '',
        name: 'VMainPage',
        component: () => import('../views/VMainPage'),
        meta: {
          title:'Мой профиль и достижения',
        },
      },
      ...auth,
      ...sections,
      ...dictionary,
      ...booking,
      ...employees,
      ...organization,
      ...classes,
      ...news,
      ...advert,
      ...profile,
      ...students,
      ...trialLesson,
      ...appointment,
      ...project,
      ...meeting,
      ...survey,
      ...test,
      ...questionnaire,
      ...dishes
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (store.getters.loggedIn) {
      store.commit('SET_LOADER', true);
        API.users.profile().then((res) => {
        if (!res.error) {
          store.commit('setProfile', res.data);
        } else {
          return store.dispatch('logout');
        }
      }).finally(() => {
        store.commit('SET_LOADER', false);
      });
  }
  if (to.path !== '/login' && !store.getters['loggedIn']) next('/login')

  if (to.path === '/login' && store.getters['loggedIn']) {
    return next({ name: 'home' });
  }
  return next();

})

router.afterEach(async (to) => {
  if (!['VAuth', 'VEmailConfirm'].includes(to.name)) {
    Vue.nextTick(() => {
      //  document.getElementById('pagetitle').innerHTML =  to.meta.title;
    });

  }
});
export default router
