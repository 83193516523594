import instance  from "@/api/instance";
const END_POINT = '/api';

const booking = {
    getList: (data) => instance.get(`${END_POINT}/bookings`,data),
    add: (data) => instance.post(`${END_POINT}/bookings`, data),
    edit: (bookingId,data) => instance.put(`${END_POINT}/bookings/${bookingId}`, data),
    delete: (bookingId) => instance.delete(`${END_POINT}/bookings/${bookingId}`),
    getItem: (bookingId) => instance.get(`${END_POINT}/bookings/${bookingId}`),
}


export default {
    booking
}