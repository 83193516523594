import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView';

export default [
  {
    path: '/profile',
    component: {
      render
    },
    children: [
      {
        path: '',
        name: 'VProfile',
        component: () => import('../views/VProfile').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Мой профиль',
        },
      },
      {
        path: '/student/:id',
        name: 'VProfileMore',
        component: () => import('../views/VProfile').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Профиль ученика',
        },
      },
      {
        path: '/profile/teacher',
        name: 'VProfileTeacher',
        component: () => import('../views/VProfile/VProfileTeacher').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Мой профиль и достижения',
        },
      },
      {
        path: '/profile/chef',
        name: 'VProfileChef',
        component: () => import('../views/VProfile/VProfileChef').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Мой профиль',
        },
      },
      {
        path: '/employees/:id',
        name: 'VProfileEmploye',
        component: () => import('../views/VProfile').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Профиль сотрудника',
        },
      },
      {
        path: 'edit',
        name: 'VProfileEdit',
        component: () => import('../views/VProfile/profileEdit').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Редактирование профиля ',
        },
      },
    ]
  }
]