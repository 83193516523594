import instance  from "@/api/instance";
const END_POINT = '/api';

const group = {
    getList: (data) => instance.get(`${END_POINT}/group`,data),
    getInfo: (userId) => instance.get(`${END_POINT}/users/${userId}`),
}


export default {
    group
}