<template>
  <div class="nav-bar-asside">
    <a href="" class="logo">
      <img src="@/assets/img/logo/logo.svg" alt="" />
    </a>
    <div class="nav-bar-asside-content">
      <div class="nav-bar-asside-top">
        <router-link :to="link">
          <div class="asside-profile">
            <div class="asside-profile__icon">
              <img :src="profileAvatar" alt="" />
            </div>
            <div class="asside-profile__content">
              <div class="asside-profile__name">
                {{ getUserFullName }}
              </div>
              <div class="asside-profile__info">
                {{ getLogin }}
              </div>
            </div>
            <div class="asside-profile-link-icon">
              <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 13L7 7L1 1" stroke="white" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </router-link>

        <ul class="nav-list">
          <li v-for="(navigation, index) in nav" :key="index">

            <router-link v-if="navigation.id != 14" tag="a" :to="{ path: navigation.url, meta: { title: 'private' } }"
              class="nav-item" :class="{ active: $route.fullPath.includes(navigation.url) }">
              <div class="nav-item__icon" v-html="navigation.icon"></div>
              {{ navigation.name }}
            </router-link>
          </li>
          <li >
            <div class="nav-item" @click="$store.dispatch('logout')">
              <div class="nav-item__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9"
                    stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M16 17L21 12L16 7" stroke="#fff" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M21 12H9" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <span >Выход</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="nav-bar-asside-bottom">
        <div class="nav-bar-lang">
          <v-lang />
        </div>
        <div class="app-link">
          <a href="" class="app-link__item">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.73155 1.77675C9.26009 1.15641 10.1539 0.696482 10.8911 0.666672C10.9853 1.52597 10.6389 2.38763 10.1279 3.00749C9.61497 3.62688 8.77602 4.10905 7.95222 4.04517C7.8415 3.20386 8.25647 2.32564 8.73155 1.77675ZM12.9272 13.5111C12.3154 14.405 11.6813 15.2955 10.6825 15.3135C9.70014 15.3319 9.38406 14.7315 8.26215 14.7315C7.1393 14.7315 6.7882 15.296 5.8584 15.3324C4.89453 15.3684 4.16016 14.3685 3.54361 13.4775C2.28258 11.6567 1.32061 8.33171 2.61428 6.08694C3.25497 4.97119 4.4029 4.26709 5.64784 4.24769C6.59609 4.23019 7.48992 4.88649 8.06815 4.88649C8.64779 4.88649 9.73421 4.09817 10.8769 4.21457C11.3549 4.23397 12.6968 4.40668 13.5589 5.66771C13.4898 5.71124 11.9563 6.60318 11.9747 8.46041C11.9951 10.6791 13.9209 11.4159 13.9431 11.4272C13.9252 11.4774 13.6342 12.4782 12.9272 13.5111Z"
                fill="#A4A2B7" />
            </svg>
            Загрузить в AppStore
          </a>
          <a href="" class="app-link__item">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_11453_6978)">
                <path
                  d="M3.70739 0.859793L12.0407 5.32124L9.498 7.86392L2.39837 0.764292C2.8047 0.6161 3.2743 0.627971 3.70739 0.859793ZM1.33325 14.532V2.28166C1.33325 1.85363 1.49374 1.48001 1.74834 1.20017L8.95502 8.40679L1.74834 15.6135C1.49374 15.3336 1.33325 14.9601 1.33325 14.532ZM3.70739 15.9539C3.2743 16.1857 2.80475 16.1975 2.39837 16.0494L9.49794 8.94977L12.0406 11.4925L3.70739 15.9539ZM15.1483 9.82872L12.7479 11.1138L10.0409 8.40679L12.7479 5.6998L15.1483 6.98492C16.2838 7.59284 16.2838 9.22086 15.1483 9.82872Z"
                  fill="#A4A2B7" />
              </g>
              <defs>
                <clipPath id="clip0_11453_6978">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Доступно в Google Play
          </a>
        </div>
        <div class="copy-right">© 2022 DarynSoft. Все права защищены</div>
      </div>
    </div>
  </div>
</template>

<script >
import VLang from "@/components/VLang";
import API from "@/api";
import { mapGetters } from "vuex";
export default {
  name: "VNavbar",
  computed: {
    ...mapGetters([
      "profileAvatar",
      "getUserFullName",
      "getLogin",
      "getRoleId",
    ]),
    typeRoleClass() {
      return [
        { class: "white-asside" },
        { class: "white-asside" }
      ];
    },

  },
  data() {
    return {
      nav: [],
      link: {},
      componentKey: 0,
    };
  },
  
  watch: {
    getRoleId() {
    this.getNavList();
    this.getProfileLink();
    },
  },
  mounted(){
    this.getNavList();
        this.getProfileLink();
  },

  methods: {
    getProfileLink() {
      if (this.getRoleId == 9) {
        this.link.name = 'VProfileChef'
      } else if (this.getRoleId == 3 || this.getRoleId == 5 || this.getRoleId == 6 || this.getRoleId == 7) {
        this.link.name = 'VProfileTeacher'
      } else {
        this.link.name = 'VProfile'
      }
    },
    getNavList() {
      const params = {
        role_id: this.getRoleId,
      }
      API.navigation.getLocalizationList({ params }).then((res) => {
        this.nav = res.data.navigations;
      });
    },
  },
  components: { VLang },
};
</script>

<style scoped >
.nav-item:hover {
  cursor: pointer;
}
</style>
