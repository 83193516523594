import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView';

export default [
  {
    path: '/sections',
    component: {
      render
    },
    children: [
      {
        path: '',
        name: 'VSectionList',
        component: () => import('../views/VSection').catch(errorHandler),
        meta: {
          authorization: false,
          title:' ',
        },
        props: true
      },
      {
        path: ':id',
        name: 'VSectionInner',
        component: () => import('../views/VSection/VSectionInner').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Кружок',
        },
      },
    ]
  }
]