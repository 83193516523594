import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView';

export default [
  {
    path: '',
    component: {
      render
    },
    children: [
      {
        path: 'projects',
        name: 'VProject',

        component: () => import('../views/VProject').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Проекты',
        },
      },
      {
        path: 'project-inner',
        name: 'VProjectInner',
        component: () => import('../views/VProject/VProjectInner').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Проект'
        },
      },
      {
        path: 'my-project',
        name: 'VMyProject',
        component: () => import('../views/VProject/VMyProject').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Мой проект'
        },
      },
    ]
  }
]