import instance  from "@/api/instance";
const END_POINT = '/api';

const employee = {
    getList: (data) => instance.get(`${END_POINT}/employees`,data),
}


export default {
    employee
}