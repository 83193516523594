import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView';

export default [
  {
    path: '',
    component: {
      render
    },
    children: [
      {
        path: '/dishes',
        name: 'VDishesMenu',
        component: () => import('../views/VDishesMenu').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Блюда',
        },
      },
      
    ]
  }
]