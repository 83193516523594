import Vue from 'vue';
import Vuex from 'vuex';
import auth from "./modules/auth";
import notification from "./modules/notification";
import loader from "./modules/loader";
import translater from "./modules/translater";
import navigation from "./modules/navigation";
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        notification,
        loader,
        translater,
        navigation
    }
})