import instance  from "@/api/instance";
const END_POINT = '/api';

const adverts = {
    getList: (data) => instance.get(`${END_POINT}/announcements`,data),
    add: (data) => instance.post(`${END_POINT}/announcements`, data),
    delete: (advertId) => instance.delete(`${END_POINT}/announcements/${advertId}`),
    edit: (advertId,data) => instance.put(`${END_POINT}/announcements/${advertId}`, data),
    getItem: (advertId) => instance.get(`${END_POINT}/announcements/${advertId}`),
    getItemEdit: (advertId) => instance.get(`${END_POINT}/announcements-edit/${advertId}`),
}


export default {
    adverts
}