import instance  from "@/api/instance";
const END_POINT = '/api';

const news = {
    getList: (data) => instance.get(`${END_POINT}/news`,data),
    add: (data) => instance.post(`${END_POINT}/news`, data),
    delete: (newsId) => instance.delete(`${END_POINT}/news/${newsId}`),
    edit: (newsId,data) => instance.put(`${END_POINT}/news/${newsId}`, data),
    getItem: (newsId) => instance.get(`${END_POINT}/news/${newsId}`),
    getItemEdit: (newsId) => instance.get(`${END_POINT}/news-edit/${newsId}`),
}


export default {
    news
}