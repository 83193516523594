import API from '@/api';

async function reqTranslate() {
    return API.translates.translate().then((res) => {
      return res.data;
    })
}

export default async function (store, VueInitial) {
  store.commit('setLangLoading', true);

  const res = await reqTranslate();
    store.commit('setLang', res);
    store.commit('setLangLoading', false);
  if (VueInitial) {
    VueInitial();
  }
}
