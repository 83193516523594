import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView';

export default [
  {
    path: 'booking',
    component: {
      render
    },
    children: [
      {
        path: '',
        name: 'VBooking',
        component: () => import('../views/VBooking').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Бронирование',
        },
      },
      {
        path: ':id',
        name: 'VBookingInner',
        component: () => import('../views/VBooking/VBookingInner').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Бронирование',
        },
      },
    ]
  }
]