const state = {
    loader: false
}

const getters = {
    loader: (state) => state.loader
}

const mutations = {
    SET_LOADER(state, loader) {
        state.loader = loader
    }
}

const actions = {
    LOADER({ commit }, { loader }) {
        commit('SET_LOADER', loader)
    }
}

export default {
    state,
    mutations,
    getters,
    actions
}