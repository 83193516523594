import API from '@/api'
import router from "@/router";
import errorHandler from "@/helpers/errorHandler";

const state = {
    navigations:[],
}

const mutations = {
    SET_NAVIGATION(state, payload) {
        state.navigations = payload;
    },
}

const getters = {
    getNavigation: (state) => !!state.navigations,
}
const actions = {
    navigation({ commit }, navigation) {
        commit('SET_NAVIGATION', navigation);
    },
}

export default {
    state,
    mutations,
    getters,
    actions
}