import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView';

export default [
  {
    path: 'questionnaires',
    component: {
      render
    },
    children: [
      {
        path: '/',
        name: 'VQuestionnaires',
        component: () => import('../views/VQuestionnaires').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Опросы',
        },
      },
      {
        path: '/newquiz',
        name: 'VNewQuestionnaire',
        component: () => import('../views/VQuestionnaires/VNewQuestionnaire').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Опросы'
        },
      },
      {
        path: ':id',
        name: 'VQuestionnaire',
        component: () => import('../views/VQuestionnaires/VQuestionnaire').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Опросы'
        },
      },
    ]
  }
]