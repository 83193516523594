<template>
    <div class="wrapper">
        <v-navbar v-if="!$route.fullPath.includes('login') "
        />
        <v-header v-if="!$route.fullPath.includes('login') " />
        <v-loader />
        <div    class="page-block" :class="{ 'full-page': $route.fullPath.includes('login')}">
          <router-view/>
        </div>
    </div>  
</template>
<script>
  import 'vue-select/dist/vue-select.css'
  import '@/assets/scss/index.scss';
  import VHeader from "@/components/VHeader";
  import VNavbar from "@/components/VNavbar";
  import VLoader from "@/components/VLoader";
  export default {
    name: 'App',
    components: {
      VNavbar,VHeader,VLoader
    },
    data() {
      return {
      }
    },
   
    methods: {
    }
  }
  </script>
