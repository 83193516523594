import { mapGetters } from 'vuex';

const Translator = {
  install(Vue, options) {
    Vue.mixin({
      computed: {
        ...mapGetters({
          trans: 'trans',
        }),
      },
    });

    Vue.prototype.$trans = {
      get: (key = '', params) => options.store.getters.trans(key, params),
    };
  },
};

export default Translator;
