import instance  from "@/api/instance";
const END_POINT = '/api';

const certificates = {
    getList: (data) => instance.get(`${END_POINT}/certificates`,data),
    add: (data) => instance.post(`${END_POINT}/certificates`, data),
    edit: (certificateId,data) => instance.put(`${END_POINT}/certificates/${certificateId}`, data),
    delete: (certificateId) => instance.delete(`${END_POINT}/certificates/${certificateId}`),
}


export default {
    certificates
}