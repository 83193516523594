import instance  from "@/api/instance";
const END_POINT = '/api';

const organizations = {
    getList: (data) => instance.get(`${END_POINT}/organizations`,data),
    getСategoryListLocalization: () => instance.get(`${END_POINT}/organization-categories/list`),
    add: (data) => instance.post(`${END_POINT}/organizations`, data),
    enter: (organizationId) => instance.post(`${END_POINT}/user-organizations/${organizationId}`),
    delete: (organizationId) => instance.delete(`${END_POINT}/organizations/${organizationId}`),
    edit: (organizationId,data) => instance.put(`${END_POINT}/organizations/${organizationId}`, data),
    getItem: (organizationId,data) => instance.get(`${END_POINT}/organizations/${organizationId}`,data),
}


export default {
    organizations
}