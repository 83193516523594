import _ from 'lodash';

export default {
  state: {
    ru: {},
    kz: {},
    langLoading: true,
  },
  mutations: {
    setLang(state, payload = {}) {
      const lang = window.localStorage.getItem('language') || 'kz';
      console.log(payload);
      state[lang] = {
        ...state[lang],
        ...payload,
      };
    },
    setLangLoading(state, payload) {
      state.langLoading = payload;
    },
  },
  actions: {
    setLang({ commit }, payload = {}) {
      commit('setLang', payload);
    },
  },
  getters: {
    trans: (state, getters) => (str = '', attrs = {}) => {
      let strTrans = _.get(state[getters.interfaceLang], str);

      if (!state.langLoading) {
        if (strTrans !== undefined && typeof strTrans === 'string') {
          if (_.isObject(attrs)) {
            _.forEach(attrs, (value, key) => {
              strTrans = strTrans.replace(`:${key}`, value);
            });
            return strTrans;
          }
        }
        return str;
      }

      return '';
    },
    getLangLoading: (state) => state.langLoading,
  },
};
