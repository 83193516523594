import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView';

export default [
  {
    path: '',
    component: {
      render
    },
    children: [
      {
        path: 'test',
        name: 'VTest',

        component: () => import('../views/VTest').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Конструктор тестов',
        },
      },
      {
        path: 'new-test',
        name: 'VNewTest',

        component: () => import('../views/VTest/VNewTest').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Конструктор тестов',
        },
      },
      {
        path: 'test-inner',
        name: 'VTestInner',

        component: () => import('../views/VTest/VTestInner').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Конструктор тестов',
        },
      },
      {
        path: 'test-list',
        name: 'VTestList',

        component: () => import('../views/VTest/VTestList').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Конструктор тестов',
        },
      },
      {
        path: 'test-page',
        name: 'VTestPage',

        component: () => import('../views/VTest/VTestPage').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Тесты',
        },
      },
      {
        path: 'test-question',
        name: 'VTesQuestion',

        component: () => import('../views/VTest/VTestQuestion').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Тесты',
        },
      },
    ]
  }
]