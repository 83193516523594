import instance  from "@/api/instance";
const END_POINT = '/api';

const students = {
    getList: (data) => instance.get(`${END_POINT}/students`,data),
    add: (data) => instance.post(`${END_POINT}/students`, data),
    delete: (newsId) => instance.delete(`${END_POINT}/students/${newsId}`),
    edit: (newsId,data) => instance.put(`${END_POINT}/students/${newsId}`, data),
    getItem: (newsId) => instance.get(`${END_POINT}/students/${newsId}`),
    getItemEdit: (newsId) => instance.get(`${END_POINT}/students-edit/${newsId}`),
}


export default {
    students
}