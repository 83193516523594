import axios from 'axios';
import store from "@/store";
import Swal from "sweetalert2";
export const errorCodes = {
    ERR_401: 401,
    ERR_402: 402,
    ERR_403: 403,
    ERR_404: 404,
    ERR_412: 412,
    ERR_422: 422,
    ERR_423: 423,
    ERR_500: 500,
};


function notify(message) {
    Swal.fire({
        position: 'top-end',
        icon: "error",
        html: message,
        customClass:'alert-error',
        showConfirmButton: false,
        timer:2000
    });
}
function catch422(data) {
    const response = {
      error: true,
      errorType: errorCodes.ERR_422,
      data,
    };
    let errorMessage = '';
    if (!data.errors) {
    console.log(data.message)

      notify( data.message);
    } else if (Array.isArray(data.errors)) {
      notify( data.errors[0]);
    } else if (data.errors) {
        
      Object.keys(data.errors).forEach((key) => {
       
        errorMessage +=  '<span>'+key +': '+data.errors[key]+ '</span>';
      });

      notify(errorMessage);
    }
  
    return response;
  }
   function catch401(data) {
    const response = {
      error: true,
      errorType: errorCodes.ERR_401,
      data: {
        message: data.message ?? data,
      },
    };
  
    if (!data.action) {
      notify(response.data.message);
    }
     store.dispatch('logout');
  }

function catchDefault(code, data) {
    const response = {
      error: true,
      errorType: code,
      data: {
        message: data.message ?? data,
      },
    };
  
    if (!data.action) {
      notify(response.data.message);
    }
  
    return response;
}
const instance = axios.create({
    baseURL: (process.env.VUE_APP_SERVER_URL !== undefined) ? process.env.VUE_APP_SERVER_URL : 'https://pro.daryn.online',
});

instance.interceptors.request.use((config) => {
   
    const token = store.state.auth.token;
    config.headers = {
        Accept: 'application/json',
        'X-Localization': store.state.auth.lang,
        'Content-Type': 'application/json',
    }
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
        return config;
    },
    (error) => Promise.reject(error),
    
);

instance.interceptors.response.use(async(res) => {
    return (res && res.data) || res;
},  (error) => {
    
    const {
        status,
        data,
    } = error.response;
    switch(status) {
        case errorCodes.ERR_401: return catch401.call(this, data);
        case errorCodes.ERR_402: return catch402.call(this, status, data);
        case errorCodes.ERR_412: return catch412.call(this, data, err);
        case errorCodes.ERR_422: return catch422.call(this, data);
        default: return catchDefault.call(this, status, data);
    }
  
});

export default instance;