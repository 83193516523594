import instance  from "@/api/instance";
const END_POINT = '/api';

const dishes = {
    getList: (data) => instance.get(`${END_POINT}/dishes`,data),
    add: (data) => instance.post(`${END_POINT}/dishes`, data),
    delete: (dishId) => instance.delete(`${END_POINT}/dishes/${dishId}`),
    edit: (dishId,data) => instance.put(`${END_POINT}/dishes/${dishId}`, data),
    getItem: (dishId) => instance.get(`${END_POINT}/dishes/${dishId}`),
    getItemEdit: (dishId) => instance.get(`${END_POINT}/dishes-edit/${dishId}`),
}


export default {
    dishes
}