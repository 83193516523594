import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView';

export default [
  {
    path: 'trial-lesson',
    component: {
      render
    },
    children: [
      {
        path: '',
        name: 'VTrialLesson',

        component: () => import('../views/VTrialLesson').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Организация',
        },
      },
    ]
  }
]