import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router';
import store from '@/store';
import Clipboard from 'v-clipboard'
import Bootstrap from 'bootstrap/dist/js/bootstrap';
import BootstrapVue from "bootstrap-vue"
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import Translator from '@/plugins/Translator';
import moment from 'moment'
import init from '@/configuration/init';
Vue.prototype.$moment = moment;

Vue.config.productionTip = false
Vue.use(Clipboard);
Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
Vue.use(Bootstrap);
Vue.use(Vuelidate);
Vue.use(Translator, {
  store,
});
init(store, () => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
});
