import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView';

export default [
  {
    path: '',
    component: {
      render
    },
    children: [
      {
        path: 'languages',
        name: 'VLanguages',
        component: () => import('../views/VDictionary/VLanguages').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Языки'
        },
      },
      {
        path: 'cabinets',
        name: 'VCabinets',
        component: () => import('../views/VDictionary/VCabinets').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Кабинеты'
        },
      },
      {
        path: 'dishes-category',
        name: 'VDishes',
        component: () => import('../views/VDictionary/VDishes').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Столовая'
        },
      },
      {
        path: 'equipments',
        name: 'VEquipments',
        component: () => import('../views/VDictionary/VEquipments').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Техника'
        },
      },
      {
        path: 'groups',
        name: 'VGroups',
        component: () => import('../views/VDictionary/VGroups').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Группы'
        },
      },
      {
        path: 'organaizes-category',
        name: 'VOrganaizes',
        component: () => import('../views/VDictionary/VOrganaizes').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Категории организаций'
        },
      },
      {
        path: 'sections-category',
        name: 'VLessonsDictionary',
        component: () => import('../views/VDictionary/VLessons').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Категории кружков'
        },
      },
      {
        path: 'positions',
        name: 'VPositions',
        component: () => import('../views/VDictionary/VPositions').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Должность'
        },
      },
      {
        path: 'navigations',
        name: 'VNavigations',
        component: () => import('../views/VDictionary/VNavigations').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Навигация'
        },
      },
    ]
  }
]