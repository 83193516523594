import instance  from "@/api/instance";
const END_POINT = '/api';

const questionnaires = {
    getList: () => instance.get(`${END_POINT}/surveys/list`),
    getItem: (id) => instance.get(`${END_POINT}/surveys/${id}/results`),
    getDetailResult:(survyeId,asnwerId)=>instance.get(`${END_POINT}/surveys/${survyeId}/results/${asnwerId}`),
    edit: (certificateId,data) => instance.put(`${END_POINT}/certificates/${certificateId}`, data),
    delete: (certificateId) => instance.delete(`${END_POINT}/certificates/${certificateId}`),
    answer: (survyeId,questionId,data) => instance.post(`${END_POINT}/surveys/${survyeId}/answer/${questionId}`, data),
}


export default {
    questionnaires
}