import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView';

export default [
  {
    path: 'employees',
    component: {
      render
    },
    children: [
      {
        path: '',
        name: 'VEmployees',
      
        component: () => import('../views/VEmployees').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Сотрудники',
        },
      },
    ]
  }
]