import instance  from "@/api/instance";
const END_POINT = '/api';

const users = {
    userList: () => instance.get(`${END_POINT}/users`),
    profile: () => instance.get(`${END_POINT}/profile`),
    userInfo: (userId) => instance.get(`${END_POINT}/users/${userId}`),
    getComments: (userId) => instance.get(`${END_POINT}/users/${userId}/comments`),
    edit: (userId,data) => instance.post(`${END_POINT}/profile/${userId}`,data),
    changepswd :(data) => instance.post(`${END_POINT}/change-password`, data),
}

export default {
    users
}