<template>
  <div>
    <header class="header">
      <h1 class="page-title" id="pagetitle">
        {{trans($route.meta.title)}}
      </h1>
      <div class="header-right">
        <div class="wallet">
          кошелек
          <div class="wallet-sum">
            0 <span>〒</span>
          </div>
          <div class="wallet-icon">
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 13L7 7L1 1" stroke="#A4A2B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <div class="notification">
          <v-dropdown>
            <template #base>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z" stroke="#010326" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.73 21C13.5542 21.3031 13.3019 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21" stroke="#010326" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </template>
            <template #dropdown="{  }">
              <div class="notification-dropdown">
                <!--                     <ul class="notification-list">-->
                <!--                        <li @click="$store.dispatch('logout')">-->
                <!--                           Выход-->
                <!--                        </li>-->
                <!--                     </ul>-->
                <div class="notice__box">
                  <h6 class="notice__box-title">Уведомления</h6>
                  <div class="notice__body">
                    <img src="@/assets/img/icons/notice.svg" alt="">
                    <div class="notice__description">
                      <p>Вас пригласили пройти опрос <a href="#" @click="showQuiz">Самый лучший препод</a></p>
                      <span>5 минут назад</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </v-dropdown>
        </div>
      </div>
    </header>

    <v-popup v-if="isQuizVisible">
      <div class="questionnaire__modal">
        <div class="questionnaire__modal-title">Опрос</div>
        <div class="questionnaire__modal-content">
          <div class="questionnaire__modal-body">
            <img src="@/assets/img/icons/quiz-icon.svg" alt="quiz">
            <div class="questionnaire__title">Опрос о смысле жизни в кино</div>
            <div class="questionnaire__description">
              <p>Опрос сделан для твоего класса, по теме кризиса. Пройдите опрос до 24 декабря. Чтобы оценить ваши мозги</p>
            </div>
          </div>
          <div class="questionnaire__modal-btns">
            <button class="questionnaire__modal-btn reminder-btn">Напомните в другой раз</button>
            <button class="questionnaire__modal-btn take-quiz__btn">Пройти</button>
          </div>
        </div>
        <div class="pop-up-close" @click="closeModal">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4098 11.9991L17.7062 7.71268C17.8944 7.52453 18.0001 7.26935 18.0001 7.00326C18.0001 6.73718 17.8944 6.482 17.7062 6.29385C17.5181 6.1057 17.2629 6 16.9968 6C16.7307 6 16.4755 6.1057 16.2874 6.29385L12.0009 10.5903L7.71445 6.29385C7.5263 6.1057 7.27111 6 7.00503 6C6.73895 6 6.48376 6.1057 6.29562 6.29385C6.10747 6.482 6.00177 6.73718 6.00177 7.00326C6.00177 7.26935 6.10747 7.52453 6.29562 7.71268L10.5921 11.9991L6.29562 16.2856C6.20196 16.3785 6.12763 16.489 6.0769 16.6108C6.02618 16.7325 6.00006 16.8631 6.00006 16.995C6.00006 17.1269 6.02618 17.2575 6.0769 17.3793C6.12763 17.501 6.20196 17.6116 6.29562 17.7044C6.3885 17.7981 6.49901 17.8724 6.62077 17.9232C6.74253 17.9739 6.87313 18 7.00503 18C7.13693 18 7.26753 17.9739 7.38929 17.9232C7.51105 17.8724 7.62156 17.7981 7.71445 17.7044L12.0009 13.408L16.2874 17.7044C16.3803 17.7981 16.4908 17.8724 16.6125 17.9232C16.7343 17.9739 16.8649 18 16.9968 18C17.1287 18 17.2593 17.9739 17.3811 17.9232C17.5028 17.8724 17.6133 17.7981 17.7062 17.7044C17.7999 17.6116 17.8742 17.501 17.9249 17.3793C17.9757 17.2575 18.0018 17.1269 18.0018 16.995C18.0018 16.8631 17.9757 16.7325 17.9249 16.6108C17.8742 16.489 17.7999 16.3785 17.7062 16.2856L13.4098 11.9991Z" fill="#4D4F67"/>
          </svg>
        </div>
      </div>
    </v-popup>

    <v-popup v-if="isQuizSuccessVisible">
      <div class="survey__modal">
        <div class="survey__modal-title">Опрос</div>
        <div class="survey__modal-content">
          <div class="survey__modal-body">
            <div class="survey__modal-subtitle">
              <p>Вы прошли опрос! Спасибо за ваше время</p>
            </div>
            <img src="@/assets/img/icons/check.svg" alt="">
          </div>
          <button class="questionnaire__pass-btn">Отлично!</button>
        </div>
        <div class="pop-up-close" @click="closeModal">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4098 11.9991L17.7062 7.71268C17.8944 7.52453 18.0001 7.26935 18.0001 7.00326C18.0001 6.73718 17.8944 6.482 17.7062 6.29385C17.5181 6.1057 17.2629 6 16.9968 6C16.7307 6 16.4755 6.1057 16.2874 6.29385L12.0009 10.5903L7.71445 6.29385C7.5263 6.1057 7.27111 6 7.00503 6C6.73895 6 6.48376 6.1057 6.29562 6.29385C6.10747 6.482 6.00177 6.73718 6.00177 7.00326C6.00177 7.26935 6.10747 7.52453 6.29562 7.71268L10.5921 11.9991L6.29562 16.2856C6.20196 16.3785 6.12763 16.489 6.0769 16.6108C6.02618 16.7325 6.00006 16.8631 6.00006 16.995C6.00006 17.1269 6.02618 17.2575 6.0769 17.3793C6.12763 17.501 6.20196 17.6116 6.29562 17.7044C6.3885 17.7981 6.49901 17.8724 6.62077 17.9232C6.74253 17.9739 6.87313 18 7.00503 18C7.13693 18 7.26753 17.9739 7.38929 17.9232C7.51105 17.8724 7.62156 17.7981 7.71445 17.7044L12.0009 13.408L16.2874 17.7044C16.3803 17.7981 16.4908 17.8724 16.6125 17.9232C16.7343 17.9739 16.8649 18 16.9968 18C17.1287 18 17.2593 17.9739 17.3811 17.9232C17.5028 17.8724 17.6133 17.7981 17.7062 17.7044C17.7999 17.6116 17.8742 17.501 17.9249 17.3793C17.9757 17.2575 18.0018 17.1269 18.0018 16.995C18.0018 16.8631 17.9757 16.7325 17.9249 16.6108C17.8742 16.489 17.7999 16.3785 17.7062 16.2856L13.4098 11.9991Z" fill="#4D4F67"/>
          </svg>
        </div>
      </div>
    </v-popup>
  </div>
</template>

<script >
import VDropdown from "@/components/VDropDown";
import VPopup from "@/components/VPopup";
export default {
   name: 'VHeader',
   components: {VDropdown}, VPopup,
   data() {
       return {
         isQuizVisible: false,
         isQuizSuccessVisible: false,
       }
   },
  methods: {
    showQuiz() {
      this.isQuizVisible = true;
      this.isQuizSuccessVisible = false;
    },
    closeModal() {
      this.isQuizVisible = false;
      this.isQuizSuccessVisible = false;
      this.$emit('closeModal');
    }
  }
}
</script>

<style scoped >
</style>
