<template>
   <div>
    <div class="lang" :class="bgColor">
        <div class="lang-item " 
        :class="{active: activeLang === 'kz'}"
        @click="handleSelectLang('kz')">
            Қазақша 
        </div>
        <div class="lang-item" 
        @click="handleSelectLang('ru')"
        :class="{active: activeLang === 'ru'}">
            Русский
        </div>
    </div>
   </div>
</template>

<script >
import {mapMutations } from 'vuex';
import store from "@/store";
export default {
    props:{
        bgColor:{
            type:String,
            default:'',
            activeLang:'',
        }
    },
    name: 'VLang',
    data() {
       return {
        lang: [
            {
            type: 'kz',
            name: 'Қазақша'
            },
            {
            type: 'ru',
            name: 'Русский'
            }
        ]
       }
    },
    created:function(){
        this.activeLang = store.state.auth.lang
    },
  components: {},
 
  methods:{
    ...mapMutations([
    'setLocale',
]),
    handleSelectLang(locale) {
      this.setLocale(locale);
    },
  }
}
</script>

<style scoped  lang="scss">
    .lang.light{
        background:  rgba(255, 255, 255, 0.12);
        color: #010326;
        .lang-item {
            background: #fff;
            &.active,&:hover {
                color:#fff;
                background: rgba(255, 255, 255, 0.12);
            }
        }
        
    }   
</style>
