import errorHandler from "../helpers/errorHandler";
import render from '../helpers/renderRouterView';

export default [
  {
    path: 'news',
    component: {
      render
    },
    children: [
      {
        path: '',
        name: 'VNews',

        component: () => import('../views/VNews').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Новости',
        },
      },
      {
        path: ':id',
        name: 'VNewsInner',

        component: () => import('../views/VNews/VNewsInner').catch(errorHandler),
        meta: {
          authorization: false,
          title:'Новости',
        },
      },

    ]
  }
]