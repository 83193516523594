import instance  from "@/api/instance";
const END_POINT = '/api';

const sections = {
    getList: (data) => instance.get(`${END_POINT}/sections`,data),
    getСategoryListLocalization:() => instance.get(`${END_POINT}/section-categories/list`),
    add: (data) => instance.post(`${END_POINT}/sections`, data),
    enter: (sectionId) => instance.post(`${END_POINT}/user-sections/${sectionId}`),
    delete: (sectionId) => instance.delete(`${END_POINT}/sections/${sectionId}`),
    edit: (sectionId,data) => instance.put(`${END_POINT}/sections/${sectionId}`, data),
    getItem: (sectionId,data) => instance.get(`${END_POINT}/sections/${sectionId}`,data),
}


export default {
    sections
}