<template>
  <div class="pop-up">
    <div class="pop-up-container">
      <slot />
    </div>
  </div>
</template>


<script>
export default {
  name: 'VPopup',
}
</script>


<style scoped lang="scss">

</style>